.dashboard-container {
  width: 99.5%;
  height: 99%;
}

.download-excel {
  color: black;
  position: absolute;
  right: 40px;
  top: 12px;
  width: 15px;
  height: 15px;
  cursor: pointer;
  visibility: visible;
}
.hidden {
  visibility: hidden;
}
.label-excel {
  position: absolute;
  right: 40px;
  top: 44px;
  font-size: x-small;
  color: white;
  background-color: black;
  border-radius: 5px;
  padding: 5px;
  visibility: hidden;
}
:hover + .label-excel {
  visibility: visible;
}
