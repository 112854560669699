.eyeIcon {
  position: relative;
  right: 30px;
  color: #1a3e68;
  cursor: pointer;
}
.supportIcon {
  padding: 5px;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 30px;
  bottom: 20px;
  color: white;
  cursor: pointer;
  background-color: #1a3e68;
  border-radius: 100%;
  box-shadow: 1px 1px 10px 3px rgba(0, 0, 0, 0.3);
}

.supportTooltip {
  width: 300px;
  position: absolute;
  background: rgb(255, 255, 255, 0.8);
  right: 50px;
  bottom: 70px;
  padding: 5px;
  border-radius: 10px;
  box-shadow: 1px 1px 10px 4px rgba(0, 0, 0, 0.5);
}

.input-cancel {
  background-color: red;
  width: 240px;
  height: 48px;
  margin: 20px;
}
.input-confirm {
  width: 240px;
  height: 48px;
  margin: 20px;
}
.disabled {
  background-color: hsl(0, 0%, 95%);
  color: gray;
}

.package-icon {
  margin: 5px;
  height: 20px;
  display: inline-block;
}

.package-tooltip {
  position: relative;
  display: inline-block;
}

.package-tooltip .package-info {
  visibility: hidden;
  width: 300px;
  top: 0px;
  left: 30px;
  padding: 10px;
  color: black;
  background-color: rgb(220, 220, 220, 0.5);
  border-radius: 10px;
  font-family: 'Mulish';

  position: absolute;
  z-index: 1;
}

.package-tooltip:hover .package-info {
  visibility: visible;
}
