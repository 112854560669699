*{
  font-family: "Montserrat", sans-serif !important;
}
.App {
  text-align: center;
}

.flex {
  display: flex;
}
.content {
  background: linear-gradient(90deg, #FF9B71 5%, #FF6CB3  100%);
  width: 100%;
  height: 100vh;
}
.content-collapsed {
  padding-left: 90px;
}
.content-expanded {
  padding-left: 250px;
}
.none {
  padding-left: 0px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.back-arrow {
  position: relative;
  top: 22px;
  height: 30px;
  background-color: transparent;
  margin-right: -20px;
  padding-left: 25px;
  border: none;
}

header {
  font-size: 24px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
}
h6 {
  margin: 20px;
  color: #1b3768;
}
input {
  border-radius: 20px;
  height: 40px;
}
a {
  cursor: pointer;
}
.search {
  padding-left: 30px;
  padding-right: 10px;
  width: 300px;
  border: none;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15), inset 2px 2px 3px #fafafa;
}
.searchIcon {
  position: relative;
  left: 25px;
  opacity: 0.7;
}
.primary-btn {
  border: none;
  background: linear-gradient(270deg, #3dd7da 20%, #1d8be8 100%);
  border-radius: 20px;
  width: 40px;
  height: 40px;
  color: white;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.blue-btn {
  background: linear-gradient(270deg, #3dd7da 20%, #1d8be8 100%);
}
.grey-btn {
  background: gainsboro;
}
.plusIcon {
  background-color: transparent;
}

.whiteScreen {
  margin: 20px;
  height: 96vh;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
}
.main {
  width: 100%;
  padding: 20px;
}
.body {
  padding: 10px;
  height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
}
.header {
  display: flex;
  padding-inline: 25px;
  height: 30px;
}
.input {
  padding: 12px 20px;
  width: 360px;
  height: 48px;
  background-color: white;
  color: #1a3e68;
  border: 1px solid rgba(26, 62, 104, 0.24);
  border-radius: 8px;
}
.input-cancel {
  background-color: red;
  width: 240px;
  height: 48px;
  margin: 20px;
}
.input-confirm {
  width: 240px;
  height: 48px;
  margin: 20px;
}

.pagination-div {
  align-self: flex-end;
}

.flex-row {
  display: flex;
  background-color: white;
  padding: 15px;
  margin: 10px;
  border-radius: 10px;
  height: 5.6vh;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.12);
}
