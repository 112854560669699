.modal {
  background-color: rgb(255, 255, 255, 0.6);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  justify-content: center;
  align-items: center;
}
.modal_dialog {
  background: white;
  padding: 30px;
  width: 92% !important;
  margin: auto;
  border-radius: 20px;
  text-align: center;
  box-shadow: 1px 1px 30px 1px rgba(0, 0, 0, 0.431);
}
.modal_dialog2 {
  background: white;
  padding: 30px;
  width: 80vw !important;
  margin: auto;
  border-radius: 20px;
  text-align: center;
  box-shadow: 1px 1px 30px 1px rgba(0, 0, 0, 0.431);
}
.image {
  margin: -30px;
  width: 540px;
}

.modal-open {
  display: grid;
}
.modal-title {
  padding: 20px;
}
.modal-input {
  margin: 20px;
}
