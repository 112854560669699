.sideBar {
  background-color: rgba(255, 255, 255, 0.245);
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  font-size: small;
}

.expanded {
  width: 240px;
}
.collapsed {
  width: 80px;
}
.card-tittle {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px;
  margin-bottom: 30px;
  height: 30px;
}
.miniLogoCaintra {
  margin-left: -4px;
  width: 40px;
  height: 40px;
}

.logoSide{
  width: 150px;;
  height: auto;
  margin-left: 8px;
}
.card-tittle .collapsed {
  overflow: hidden;
}
.profileInfo {
  display: flex;
  align-items: center;
}
.vertical-line {
  border-right: solid #787878 1px;
  padding-right: 10px;
  margin-right: -5px;
  height: 30px;
  width: 1px;
}
.tittle {
  height: 30px;
  margin-left: 15px;
  font: 12px normal;
  font-weight: 600;
  color: #1b3769bf;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.sideBarMenuItem {
  height: auto;
  text-align: left;
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.navLink {
  color: black;
  text-decoration: none;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}
.navActive {
  background: linear-gradient(270deg, #598CF8 100%, #FF9B71 100%);
  width: 100%;
  color: white;
  border-radius: 10px;
}
.navActive .icon {
  color: white;
}
.itemContent {
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  opacity: 0.7;
}
.icon {
  position: absolute;
  left: 25px;
  width: 20px;
  height: 20px;
  color: black;
}
.label {
  padding-left: 40px;
  display: flex;
  align-items: center;
  font-weight: 500;
  margin-left: 10px;
}
.tooltips {
  position: absolute;
  color: white;
  background: linear-gradient(270deg, #598CF8 100%, #fe875f 100%);
  left: 80px;
  padding: 5px;
  border-radius: 10px;
  display: none;
  box-shadow: 1px 1px 40px 4px #fafafa;
  z-index: 1;
}

.itemContent:hover{
  color : white;
}
:hover + .tooltips {
  display: inline-block;
  animation-duration: 0.5s;
  animation-name: tooltips;
}
@keyframes tooltips {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.footer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.menuButton {
  display: flex;
  justify-content: right;
}
.expandButton {
  cursor: pointer;
  border: none;
  background-color: transparent;

  height: 50px;
  white-space: nowrap;
  opacity: 0.7;
}
.icon-expanded {
  position: absolute;
  right: 25px;
  width: 20px;
  height: 20px;
  color: black;
}

.closeSession {
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  opacity: 0.7;
}
.closeSession-collapsed {
  display: none;
}
a {
  text-decoration: none;
  color: black;
}
