.pagination {
  right: 80px;
  bottom: 40px;
  display: flex;
  width: 100px;
  height: 40px;
}

.pagination .primary-btn {
  margin: 10px;
  width: 30px;
  height: 30px;
}
