.download-image {
  width: auto;
  height: 70vh;
  margin: 20px;
  cursor: pointer;
}
.download-image:hover {
  opacity: 0.7;
}

.whiteScreen-downloads {
  overflow: auto;
}

.main-downloads {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
