.eyeIcon1 {
    position: relative;
    right: 30px;
    color: #1a3e68;
    cursor: pointer;
  }
  .eyeIcon2 {
    position: relative;
    left: -100px;
    color: #1a3e68;
    cursor: pointer;
  }
  
  .input-cancel {
    background-color: red;
    width: 240px;
    height: 48px;
    margin: 20px;
  }
  .input-confirm {
    width: 240px;
    height: 48px;
    margin: 20px;
  }
  