.company {
  width: 25%;
  align-self: center;
}
.clientName {
  width: 25%;
  align-self: center;
}
.clientEmail {
  width: 20%;
  align-self: center;
}
.clientStatus {
  width: 15%;
  align-self: center;
}
.accessEnd{
  width:15%;
  align-self: center;
}
.clientType {
  width: 15%;
  align-self: center;
}
.actions {
  width: 5%;
  align-self: center;
}
