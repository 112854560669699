.login {
  width: 100%;
  height: 100%;
  background-image: url('../../assets/Background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.internsLogo {
  width: 100%;
  height: 100%;
  background-image: url('../../assets/Logo_Interns.svg');
  background-repeat: no-repeat;
  z-index: 0;
  position: absolute;
  background-position: right bottom;
}

.blurer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(255, 155, 113,0.8) 5%,
    rgba(255, 108, 179,0.8) 100%
  );
}

.input-field {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-body {
  position: absolute;
  width: 440px;
  height: 536px;
  left: 120px;
  top: 132px;

  background: linear-gradient(
    154.7deg,
    rgba(255, 255, 255, 0.6) 3.86%,
    rgba(255, 255, 255, 0.2) 96.1%
  );
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(6px);
  border-radius: 12px;
}

.card-body .row {
  margin: 30px;
}
.vl {
  margin-top: 10px;
  margin-left: -25px;
  margin-right: -15px;
  border-right: solid #1b3768 2px;
  height: 40px;
  width: 1px;
  opacity: 0.5;
}
.logoCaintra {
  width: auto;
  float: right;
  height: 60px;
  color: #1b3768;
  background-color: transparent;
}

.card-title {
  margin-top: 5px;
  margin-left: 30px;
  font: 20px bold;
  font-weight: 600;
  color: #1b3769bf;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.startSession {
  font-size: 24px;
  color: #1a3e68;
}

.enterCredentials {
  font-size: 14px;
  color: #1a3e68;
}

.emailPassword {
  margin-bottom: 5px;
  font-size: 14px;
  color: rgba(26, 62, 104, 0.48);
}

.btn {
  background-color: #1b3768;
  color: white;
}

.eye-icon {
  position: absolute;
  right: 15.63%;
  color: #1a3e68;
  cursor: pointer;
}
