.container {
  text-align: center;
}
.toggle-switch {
  position: relative;
  width: 50px;
  height: 50px;
  display: inline-block;
  text-align: left;
  top: 8px;
}
.checkbox {
  display: none;
}
.label-switch {
  height: 30px;
  background-color: red;
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #bbb;
  border-radius: 20px;
}
.inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.inner:before,
.inner:after {
  float: left;
  width: 50%;
  height: 36px;
  padding: 0;
  line-height: 36px;
  color: #fff;
  font-weight: bold;
  box-sizing: border-box;
  border-radius: 10px;
}
.inner:before {
  width: 50px;
  content: '';
  margin: 0px;
  background-color: #060;
  color: #fff;
  box-shadow: -2px -2px 10px 5px rgba(20, 20, 20, 0.6) inset;
}
.inner:after {
  width: 50px;
  margin: 0;
  background-color: red;
  content: '';
  box-shadow: 2px -2px 10px 5px rgba(20, 20, 20, 0.45) inset;
}
.disabled:before,
.disabled:after {
  background-color: grey;
}
.switch {
  display: block;
  width: 22px;
  height: 22px;
  margin-top: 4px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 25px;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.6);
}
.checkbox:checked + .label-switch .inner {
  margin-left: 0;
}
.checkbox:checked + .label-switch .switch {
  right: 3px;
}
