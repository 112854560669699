.name {
  width: 40%;
  align-self: center;
}
.updatedBy {
  width: 20%;
  align-self: center;
  text-align: center;
}
.updatedAt {
  width: 30%;
  align-self: center;
  text-align: center;
}
.actions {
  width: 5%;
  text-align: center;
}
.actionIcon {
  margin-left: 5px;
  margin-right: 5px;
  opacity: 0.7;
  cursor: pointer;
}
.csvIcon {
  opacity: 0.7;
  cursor: pointer;
}
