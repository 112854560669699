.input-search {
    width: 95%;
  }
  
  .nameFile {
    width: 25%;
    align-self: center;
  }
  .updatedBy {
    width: 30%;
    align-self: center;
  }
  .updateDate {
    width: 30%;
    align-self: center;
  }
  .actionsEdit {
    width: 10%;
    align-self: center;
  }
  .actionsDelete {
    width: 10%;
    align-self: center;
  }
  
  .actionIcon {
    margin-left: 5px;
    margin-right: 5px;
    opacity: 0.7;
    cursor: pointer;
  }
  .status-label {
    align-items: flex-start;
    text-align: center;
    font-size: 13px;
  
    width: 65px;
    height: 22px;
    border-radius: 12px;
  }
  .active {
    color: #098129;
    background-color: #ceead6;
  }
  .inactive {
    color: red;
    background-color: rgb(235, 181, 181);
  }
  .read-only {
    border: none;
    background-color: transparent;
  }
  .btn-danger {
    background: red;
    width: 200px;
    margin: 20px;
  }
  .btn-dark {
    width: 200px;
    margin: 20px;
  }
  